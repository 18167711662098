<template lang="pug">
  .operation-block.operation-block__claim
    .operation-block__arrow
    .operation-block__data
      span ID: {{ result.id }}
    .operation-block__data
      span {{ divideDate(result.created_at).dateDay }}
      time {{ divideDate(result.created_at).time }}
    .operation-block__purchase.ml-5
      span(class='resultId') Заявка №: {{ result.id }}
    .operation-block__purchase.ml-5(v-if='!result.is_confirmed && result.is_processed && result.decree')
      span(class='resultId') Комментарий: {{ result.decree }}
    .operation-block__given.mr-5.result-status
      p(title="Статус") {{ requestTitle(result) }}
</template>

<script>
import utils from '@/components/mixins/utils';

export default {
  mixins: [utils],
  props: {
    result: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    requestTitle(request) {
      if (!request.is_confirmed && !request.is_processed) {
        return 'В процессе'
      }
      if (!request.is_confirmed && request.is_processed) {
        return 'Отказано'
      }
      if (request.is_confirmed && request.is_processed) {
        return 'Одобрено'
      }
    }
  }
}
</script>

<style lang="scss">
.operation-block__pay {
  display: flex;
  padding: 10px;
  border-radius: 10px;
}
.result-status {
  font-weight: bold;
}
</style>
