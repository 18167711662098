<template lang="pug">
.main-content.layout-main__centering.container.pb-5(
  v-loading='isLoading',
  element-loading-text='Загрузка данных...',
)
  .partner-program-code
    .partner-title
      h2.partner-title__text Партнерская программа Finex.market
      h4.partner-title__desc Зарабатывайте до 100% от комиссий сделок <br> с лучшей партнерской программой Finex.market! <br><br> С первого дня до полугода - 100% <br> От полугода до года - 50% <br> От года навсегда - 10%
    .partner-children
      .partner-note
        .partner-note-item
          .partner-note-item__number 1
          h5.partner-note-item__text Получите вашу <br> реферальную ссылку
        .partner-note-item
          .partner-note-item__number 2
          h5.partner-note-item__text Приглашайте людей <br> на Finex.market
        .partner-note-item
          .partner-note-item__number 3
          h5.partner-note-item__text Получайте комиссию <br> с каждой сделки
      //- Регистрация и коды
      .partner-program(v-if="!partnerInfo && !in_process && isFormVisible")
        .partner-program-container
          span.partner-program-container__title Хотите стать партнером сервиса?
          button.btn.btn-success(type='button', @click="gotPartner", :disabled="!agree") Стать партнером
          .agreement-block.agreement-block_width-partner
            input#check.check(type='checkbox', name='check', v-model="agree")
            label.check-label(for='check')
              | Я  согласен с правилами&nbsp;
              router-link(:to="{ name: 'Partnership' }", tag="a") партнерской программы
      .partner-program(v-if="in_process")
        .partner-program-container
          span.partner-program-container__title В данный момент ваша заявка отправлена на обработку, в ближайшее время Вы получите ответ
      .partner-program(v-if="partnershipRequests.length > 0 && !partnerInfo")
        .results
          PartnerResult(v-for="result in partnershipRequests" :key="result.id" :result="result")
      .partner-program-code.mt-5(v-if="partnerInfo")
        .partner-program-links Ваши ссылки для партнеров:
        p.partner-program-code__text Ссылка на регистрацию:
        .partner-program-code__input
          .d-e-blocks.width-block.take-set-input.take-set-input_no-right-block.input-white
            .block-wrap
              input(
              type='text',
              :value="registerPartnerLink",
              :readonly="true",
              @click="selectAddress('registerPartnerLink')",
              ref="registerPartnerLink"
              )
          button.btn.btn-success(type='button', @click="copyToClipboard(registerPartnerLink)") Копировать
        .partner-program-code__block
          p.partner-program-code__text Ваш код партнера:
          span {{partnerInfo.partner_id}}
        router-link.partner-program-code__link(:to="{ name: 'Partnership' }", tag="a") Правила партнерской программы
</template>

<script>
import {mapGetters} from 'vuex';
import {PROFILE_MAKE_PARTNERSHIP, PROFILE_PARTNERSHIP_GET, PROFILE_REQUESTS} from '@/store/actions/user';
import cfg from '../../config';
import PartnerResult from '../components/Partner/PartnerResult.vue';

export default {
  name: "AccountPartnership",
  components: {
    PartnerResult
  },
  data() {
    return {
      partnerInfo: null,
      registerPartnerLink: '',
      agree: false,
      siteUrl: cfg.domain,
      systems: [
        {
          id: 1,
          title: 'Одноуровневая система',
        },
      ],
      stages: {
        stage_1: 0.24,
        stage_2: 0.12,
        stage_3: 0.06,
      },
      activePartner: 1,
      activePeriod: 0.9,
      calc: {
        person: null,
        deals: null,
        price: null,
        comission: cfg.partnership_comission,
      },
      in_process: false,
    }
  },
  computed: {
    ...mapGetters([
      'loading',
    ]),
    isLoading() {
      return this.loading(PROFILE_PARTNERSHIP_GET) === 'loading' || this.loading(PROFILE_MAKE_PARTNERSHIP) === 'loading';
    },
    earnMoney() {
      return Math.round(this.calc.person * this.calc.deals * (this.activePeriod*(this.calc.price * this.calc.comission)));
    },
    partnershipRequests() {
      return this.$store.state.user.partnershipRequests
    },
    isFormVisible() {
      if (this.partnershipRequests.length === 0) {
        return true
      }
      return this.partnershipRequests.filter(i => !i.is_processed && !i.is_confirmed).length === 0
    }
  },
  mounted() {
    this.getStatus();
    this.$store.dispatch(PROFILE_REQUESTS);
  },
  methods: {
    selectAddress(address) {
      this.$refs[address].select();
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value)
        .then(() => {
          this.$message({
            message: 'Партнёрская ссылка успешно скопирована',
            type: 'success',
          });
          this.sendAnalytics('Account', 'partner_address_copy', ['yandex']);
        })
        .catch(err => {
          console.log('Ошибка при копировании адреса', err);
        });
    },
    getStatus() {
      this.$store.dispatch(PROFILE_PARTNERSHIP_GET).then((info) => {
        this.setInfo(info);
      });
    },
    gotPartner() {
      if (this.agree) {
        this.$store.dispatch(PROFILE_MAKE_PARTNERSHIP).then((info) => {
          this.in_process = !!info.id;
          this.sendAnalytics('Account', 'partner_address_add');
        }).catch(e => {
          console.log('Error: ', e);
        });
      }
    },
    sendAnalytics(category, action, metricksList) {
      if (metricksList) {
        this.$store.dispatch("sendAnalytics", {category, action, metricksList});
      } else {
        this.$store.dispatch("sendAnalytics", {category, action});
      }
    },
    setInfo(info) {
      this.partnerInfo = info;
      this.registerPartnerLink = `https://${this.siteUrl}/registration/${info.partner_id}`;
    }
  }
}
</script>

<style lang="scss">
.partner-program-links {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
}

.partner-title {
  background-size: cover;
  background: url('/img/partner/partner-title.png') no-repeat;
  border-radius: 6px;
  box-shadow: 0 4px 9px rgba(183, 183, 183, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 420px;
  padding-left: 50px;
  padding-right: 40px;
  margin-bottom: 15px;

  &__text {
    color: $white;
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
    font-style: normal;
    padding-bottom: 30px;
  }

  &__desc {
    color: $white;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    font-weight: normal;
  }
}

.partner-children {
  padding: 0 40px;
  position: relative;
  top: -55px;
}

.partner-note {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: $blue600;
  box-shadow: 0 4px 9px rgba(183, 183, 183, 0.5);
  border-radius: 6px;
  min-height: 135px;
  padding: 0 30px;
  margin-bottom: 15px;
  font-style: normal;
  color: #FFFFFF;

  &__title {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 5px;
  }

  &__text {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
  }
}

.partner-note-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: url('/img/partner/partner-line.svg');
  background-repeat: no-repeat;
  background-position: right center;
  min-height: 102px;

  &:last-child {
    background: none;

    h5 {
      padding-right: 0;
    }
  }

  &__number {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    color: $blue600;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }

  &__text {
    color: $white;
    line-height: 24px;
    font-size: 18px;
    font-weight: 500;
    padding-left: 12px;
    padding-right: 55px;
    margin: 0;
  }
}

.results {
  background: $white;
  padding: 10px 20px 0;
  margin-top: 15px;
  box-shadow: 0 2px 9px rgb(0 0 0 / 7%);
}
</style>
